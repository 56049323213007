
import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'History'
})
export default class History extends Vue {
  private searchInfo: { dateRange: Array<string> } = {
    dateRange: []
  }

  private loading = false
  private dataList: Array<object> = []
  page = 1
  size = 10
  total = 0

  get deviceNumber () {
    return this.$route.query.deviceNumber as string
  }

  get deviceId () {
    return this.$route.params.deviceId as string
  }

  created () {
    this.onSearch()
  }

  getData (): void {
    this.loading = true
    const data = this.searchInfo.dateRange && this.searchInfo.dateRange[0] ? {
      startTime: this.searchInfo.dateRange[0],
      endTime: this.searchInfo.dateRange[1]
    } : {}
    this.$axios.get(this.$apis.odor.selectGasDataByPage, {
      deviceId: this.deviceId,
      ...data,
      page: this.page,
      size: this.size
    }).then((res) => {
      this.total = res.total || 0
      this.dataList = res.list || []
    }).finally(() => {
      this.loading = false
    })
  }

  onSearch () {
    this.page = 1
    this.getData()
  }
}
